<template>
    <Link :href="href" :class="classes">
        <slot />
    </Link>
</template>

<script>
    import { defineComponent } from 'vue'
    import { Link } from '@inertiajs/vue3';

    export default defineComponent({
        components: {
            Link,
        },
        props: ['href', 'active'],

        computed: {
            classes() {
               // return this.sss;
                return (this.active
                    ? 'text-gray-900 font-bold'
                    : 'text-gray-700')
            }
        }
    })
</script>
